<script setup lang="ts">
import type { IconClass } from '#core/types'
import { twJoin, twMerge } from 'tailwind-merge'
import UiCollapsible from '../container/UiCollapsible.vue'

/**
 * UiCard
 *
 * Flexible and powerful card component
 *
 * @see https://github.com/forged-com/forgd/pull/1629
 *
 * Migrated from UCard to allow it to be collapsible
 *
 * @see https://github.com/forged-com/forgd/pull/1816
 * @see https://github.com/nuxt/ui/blob/dev/src/runtime/components/layout/Card.vue
 */
const props = defineProps<{
  title?: string
  tooltip?: string
  icon?: IconClass
  variant?: 'gray' | 'neutral'
  collapsed?: boolean | 'auto' // pass "auto" to persist state based on user interaction
  isCollapsable?: boolean
  isSticky?: boolean
  noPadding?: boolean
  loading?: boolean
  ui?: {
    ring?: string
    body?: string
  } & Record<string, any>
}>()

const emit = defineEmits(['opened', 'closed'])
const attrs = useAttrs()

/// variant
const background = computed(() => {
  return props.variant === 'gray'
    ? 'bg-forgd-bgd-400'
    : props.variant === 'neutral'
      ? 'bg-neutral-300'
      : 'bg-white'
})

/// layout
const padding = 'px-5 py-4 sm:px-5 sm:py-4'

const ui = computed(() => {
  return defineUi('card', {
    ...props.ui,
    background: background.value,
    divide: 'divide-y divide-forgd-bgd-600',
    ring: twMerge('ring-1 ring-forgd-bgd-600', props.ui?.ring),
    rounded: 'rounded-lg',
    shadow: 'shadow-none',
    header: {
      base: twMerge('flex h-fit min-h-[70px]', props.isSticky && `sticky top-0 z-10 ${background.value} rounded-t-lg`),
      padding: twMerge(padding, props.isCollapsable ? '!pe-4' : ''), // move collapse icon slightly to the right
    },
    body: {
      padding: twMerge(props.noPadding ? '' : padding, props.ui?.body),
    },
    footer: {
      base: 'flex h-fit min-h-[70px]',
      padding,
    },
  })
})

/// card classes
// @see https://github.com/nuxt/ui/blob/dev/src/runtime/components/layout/Card.vue
const classes = computed(() => {
  return twMerge(twJoin(
    ui.value?.base,
    ui.value?.rounded,
    ui.value?.divide,
    ui.value?.ring,
    ui.value?.shadow,
    ui.value?.background,
  ), attrs.class as string)
})

/// collapsed state
const isOpen = ref(props.collapsed !== false)

if (props.collapsed === 'auto') {
  if (!props.title) {
    console.warn('To persist the collapsed state of a card, the card requires a unique "title" prop')
  }
  else {
    const key = `forgd:card-open-state:${props.title.toLowerCase().replaceAll(/\W+/g, '-')}`

    const state = localStorage.getItem(key)
    if (state) {
      isOpen.value = state === 'true'
    }

    watch(isOpen, (value) => {
      localStorage.setItem(key, String(value))
    })
  }
}
</script>

<template>
  <div data-ui="UiCard" :data-sticky="props.isSticky ? 1 : undefined" :class="classes">
    <div
      v-if="title || $slots.header || $slots['header-text'] || $slots['header-action'] || $slots['header-outer']"
      :class="[ui?.header?.base, ui?.header?.padding, ui?.header?.background]"
      class="UiCard__header"
    >
      <!-- raw header -->
      <slot name="header-outer">
        <div class="flex items-center justify-between space-x-3 w-full">
          <!-- header -->
          <slot name="header" :toggle-collapsible="(() => isOpen = !isOpen)">
            <div class="flex items-center space-x-2">
              <!-- icon -->
              <UiIcon v-if="icon" :name="icon" size="xl" />

              <!-- text -->
              <span class="flex items-center space-x-2 text-sm/[1.5] font-semibold">
                <slot name="header-text">
                  <UiLabel :text="title" :tooltip="tooltip" />
                </slot>
              </span>
            </div>

            <!-- action -->
            <div v-if="$slots['header-action']">
              <slot name="header-action" />
            </div>
          </slot>
        </div>
      </slot>

      <!-- collapse -->
      <span
        v-if="isCollapsable"
        class="flex ms-3 justify-center items-center p-1 cursor-pointer text-gray-600 hover:text-black"
        @click="isOpen = !isOpen"
      >
        <UiIcon
          name="i-heroicons-chevron-up"
          size="lg"
          class="transition-all duration-300"
          :class="isOpen ? 'rotate-180' : ''"
        />
      </span>
    </div>

    <component
      :is="isCollapsable ? UiCollapsible : 'div'"
      :state="isCollapsable ? isOpen : undefined"
      class="flex flex-col"
      :class="ui?.divide"
      @change="(state: boolean) => emit(state ? 'opened' : 'closed')"
    >
      <div
        v-if="$slots.default"
        :class="[ui?.body?.base, ui?.body?.padding, ui?.body?.background]"
        class="UiCard__body"
      >
        <div v-if="loading" class="flex items-center justify-center w-full h-[220px]">
          <UiLoading type="lottie" loading />
        </div>
        <!-- content; use no-padding to fill space -->
        <slot v-else />
      </div>

      <div
        v-if="$slots.footer || $slots['footer-outer']"
        :class="[ui?.footer?.base, ui?.footer?.padding, ui?.footer?.background]"
        class="UiCard__footer"
      >
        <!-- raw footer -->
        <slot name="footer-outer">
          <div class="flex items-center justify-between space-x-3 w-full">
            <!-- footer -->
            <slot name="footer" />
          </div>
        </slot>
      </div>
    </component>
  </div>
</template>

<style lang="scss">
div[data-ui="UiCard"] {
  // ensure divided sections take on the same border color
  .divide-x > *,
  .divide-y > * {
    @apply border-neutral-600;
  }

  // ensure colored, non-footer cards show proper rounded corners
  .UiCard__body:last-child {
    @apply rounded-b-lg;
  }
}
</style>
